import { GET_ORDER, UPDATE_ORDER } from "./actions";
import { setReducerState } from "../../utils/common";

export function orderReducer(state = {}, action) {
  const setState = data => setReducerState(state.data, action.errors, data);

  switch (action.type) {
    case GET_ORDER:
      return setState(action.data);
    case UPDATE_ORDER:
      return setState(action.data);
    default:
      return state;
  }
}
