export const getErrorMessageByFieldName = (errors, fieldName) => {
  const error = errors.find(elem => elem.fieldName === fieldName);
  if (error) return error.errorMessage;
  return null;
};

export const findItemFromArray = (arr, value) => {
  return arr.find(elem => elem === value);
};

export const removeItemFromArray = (arr, value) => {
  return arr.filter(elem => elem !== value);
};

export const removeItemFromArrayById = (arr, id) => {
  return arr.filter(elem => elem.id !== id);
};

export const getObjectFromArrayById = (arr, id) => {
  return arr.filter(elem => elem.id === id)[0];
};

export const setReducerState = (currentData, errors, data) => {
  if (errors) return { data: currentData, errors };
  else return { data, errors: null };
};
