import { GET_MONITORING } from "./actions";
import { setReducerState } from "../../../utils/common";

export function monitoringReducer(state = { data }, action) {
  const setState = data => setReducerState(state.data, action.errors, data);

  switch (action.type) {
    case GET_MONITORING:
      return setState(action.data);
    default:
      return state;
  }
}

const data = {
  id: "MONI",
  name: "Monitoring",
  price: {
    currency: "PLN",
    year: 24,
    addedValue: 12
  },
  configs: {
    minQuantity: 2
  }
};
