import PackagesService from "../../../services/PackagesService";

export const GET_HOSTING_LIST = "GET_HOSTING_LIST";

export async function getHostingList() {
  try {
    const response = await PackagesService.getHostingList();

    return { type: GET_HOSTING_LIST, data: response };
  } catch (err) {
    return { type: GET_HOSTING_LIST, errors: err };
  }
}
