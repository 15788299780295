import { HOSTING_LIST_ROUTE, VPS_LIST_ROUTE, EMAIL_LIST_ROUTE, MONITORING_ROUTE } from "../utils/api/routes";
import { query } from "./CommonService";

export default class PackagesService {
  static async getHostingList({ currentData }) {
    return await query({ url: HOSTING_LIST_ROUTE, method: "POST", currentData });
  }

  static async getVPSList({ currentData }) {
    return await query({ url: VPS_LIST_ROUTE, method: "POST", currentData });
  }

  static async getEmailList({ currentData }) {
    return await query({ url: EMAIL_LIST_ROUTE, method: "POST", currentData });
  }

  static async getMonitoring({ currentData }) {
    return await query({ url: MONITORING_ROUTE, method: "POST", currentData });
  }
}
