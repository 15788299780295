module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Getinweb","short_name":"Getinweb","start_url":"/pl/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"88d2c5e794a9b2421d5893b67c46d1da"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/store/createStore","serialize":{"space":0,"isJSON":true,"unsafe":false},"cleanupOnClient":true,"windowKey":"__PRELOADED_STATE__"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/pl/","/pl/o-nas/","/pl/kariera/","/pl/kontakt/","/pl/wycena/","/pl/polityka-prywatnosci/","/pl/regulamin/","/pl/zamowienie/","/pl/prasa/","/pl/formularz-aplikacji/","/pl/oprogramowanie/strony-internetowe/","/pl/oprogramowanie/sklepy-internetowe/","/pl/oprogramowanie/aplikacje-webowe/","/pl/oprogramowanie/aplikacje-desktopowe/","/pl/oprogramowanie/aplikacje-mobilne/","/pl/oprogramowanie/utrzymanie-monitoring-wsparcie/","/pl/chmura/hosting/","/pl/chmura/serwery-vps/","/pl/chmura/poczta-email/","/pl/marketing/pozycjonowanie-stron-internetowych/","/pl/marketing/optymalizacja-seo/","/pl/marketing/obsluga-kampanii-reklamowych/","/pl/marketing/copywriting/","/pl/dodatkowe-uslugi/tlumaczenia/","/pl/dodatkowe-uslugi/pomoc-prawna-it/","/en/","/en/about-us/","/en/contact/","/en/estimate/","/en/privacy-policy/","/en/terms/","/en/order/","/en/press/","/en/software/websites/","/en/software/webshops/","/en/software/web-applications/","/en/software/desktop-applications/","/en/software/mobile-applications/","/en/software/maintenance-monitoring-support/","/en/cloud/hosting/","/en/cloud/vps-servers/","/en/cloud/email/","/en/marketing/website-positioning/","/en/marketing/seo-optimisation/","/en/marketing/handling-advertising-campaigns/","/en/additional-services/copywriting/","/en/additional-services/translations/","/en/it-legal-assistance/"],"workboxConfig":{"importWorkboxFrom":"cdn"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
