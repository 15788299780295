import PackagesService from "../../../services/PackagesService";

export const GET_VPS_LIST = "GET_VPS_LIST";

export async function getVPSList() {
  try {
    const response = await PackagesService.getVPSList();

    return { type: GET_VPS_LIST, data: response };
  } catch (err) {
    return { type: GET_VPS_LIST, errors: err };
  }
}
