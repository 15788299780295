import { GET_HOSTING_LIST } from "./actions";
import { setReducerState } from "../../../utils/common";

export function hostingReducer(state = { data }, action) {
  const setState = data => setReducerState(state.data, action.errors, data);

  switch (action.type) {
    case GET_HOSTING_LIST:
      return setState(action.data);
    default:
      return state;
  }
}

const data = [
  {
    id: "HOST001",
    name: "Personal",
    variant: [
      {
        id: "V1",
        name: "Personal",
        value: "",
        price: {
          currency: "PLN",
          year: 120,
          quarter: 30
        },
        modifier: []
      }
    ]
  },
  {
    id: "HOST002",
    name: "Pro",
    variant: [
      {
        id: "V1",
        name: "Pro",
        value: "",
        price: {
          currency: "PLN",
          year: 912,
          quarter: 228
        },
        modifier: []
      }
    ]
  },
  {
    id: "HOST003",
    name: "Performance",
    variant: [
      {
        id: "V1",
        name: "Performance 1",
        value: "1 vCore, 2 GB RAM",
        price: {
          currency: "PLN",
          year: 480,
          quarter: 120
        },
        modifier: []
      },
      {
        id: "V2",
        name: "Performance 2",
        value: "2 vCore, 4 GB RAM",
        price: {
          currency: "PLN",
          year: 912,
          quarter: 228
        },
        modifier: []
      },
      {
        id: "V3",
        name: "Performance 3",
        value: "3 vCore, 6 GB RAM",
        price: {
          currency: "PLN",
          year: 1296,
          quarter: 324
        },
        modifier: []
      },
      {
        id: "V4",
        name: "Performance 4",
        value: "4 vCore, 8 GB RAM",
        price: {
          currency: "PLN",
          year: 1632,
          quarter: 408
        },
        modifier: []
      }
    ]
  },
  {
    id: "HOST004",
    name: "Cloud Web",
    variant: [
      {
        id: "V1",
        name: "Cloud Web 1",
        value: "1 vCore 2.4 GHz, 2 GB RAM",
        price: {
          currency: "PLN",
          year: 480,
          quarter: 120
        },
        modifier: []
      },
      {
        id: "V2",
        name: "Cloud Web 2",
        value: "1 vCore 2.4 GHz, 4 GB RAM",
        price: {
          currency: "PLN",
          year: 912,
          quarter: 228
        },
        modifier: []
      },
      {
        id: "V3",
        name: "Cloud Web 3",
        value: "2 vCore 2.4 GHz, 8 GB RAM",
        price: {
          currency: "PLN",
          year: 1632,
          quarter: 408
        },
        modifier: []
      }
    ]
  }
];
