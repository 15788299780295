import { getCookie, removeCookie, setCookie } from "../utils/cookie";

export default class OrderService {
  static getOrder() {
    try {
      const order = getCookie("or");
      return JSON.parse(order);
    } catch (err) {
      this.removeOrder();
      return [];
    }
  }

  static removeOrder() {
    removeCookie("or");
  }

  static setOrder(data) {
    return setCookie("or", JSON.stringify(data));
  }

  static createOrder({ productId, quantity, modifier, divide }) {
    let order = this.getOrder();
    const item = { id: productId, quantity, modifier, divide };

    if (!!order) {
      let swap = false;

      order.forEach((elem, i) => {
        if (elem.id === productId && !elem.modifier.length && !elem.divide.length) {
          order[i] = { id: productId, quantity: (quantity += elem.quantity), modifier, divide };
          swap = true;
        }
      });

      if (swap) return setCookie("or", JSON.stringify(order));
      else return setCookie("or", JSON.stringify([...order, item]));
    }
    return setCookie("or", JSON.stringify([item]));
  }

  static async getOrderRequest() {
    return this.getOrder();
  }
}
