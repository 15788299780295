import OrderService from "../../services/OrderService";

export const GET_ORDER = "GET_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";

export async function getOrderRequest() {
  try {
    const data = await OrderService.getOrderRequest();

    return { type: GET_ORDER, data };
  } catch (err) {
    return { type: GET_ORDER, errors: err };
  }
}

export async function updateOrder(order) {
  OrderService.setOrder(order);

  try {
    return { type: UPDATE_ORDER, data: order };
  } catch (err) {
    return { type: UPDATE_ORDER, errors: err };
  }
}
