const API_PREFIX = "/api";
const NEWSLETTER_API_PREFIX = process.env.NEWSLETTER_API_URL;

export const CONTACT_FORM_ROUTE = `${API_PREFIX}/contact`; // POST
export const ESTIMATE_FORM_ROUTE = `${API_PREFIX}/estimate`; // POST
export const NEWSLETTER_FORM_ROUTE = `${NEWSLETTER_API_PREFIX}/newsletter`; // POST
export const ORDER_FORM_ROUTE = `${API_PREFIX}/order`; // POST
export const APPLICATION_FORM_ROUTE = `${API_PREFIX}/form/application`; // POST

// PACKAGES
export const HOSTING_LIST_ROUTE = `${API_PREFIX}/packages/hosting`; // POST
export const VPS_LIST_ROUTE = `${API_PREFIX}/packages/vps`; // POST
export const EMAIL_LIST_ROUTE = `${API_PREFIX}/packages/email`; // POST
export const MONITORING_ROUTE = `${API_PREFIX}/packages/monitoring`; // POST
