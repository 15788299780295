import PackagesService from "../../../services/PackagesService";

export const GET_EMAIL_LIST = "GET_EMAIL_LIST";

export async function getEmailList() {
  try {
    const response = await PackagesService.getEmailList();

    return { type: GET_EMAIL_LIST, data: response };
  } catch (err) {
    return { type: GET_EMAIL_LIST, errors: err };
  }
}
