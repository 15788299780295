// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-about-index-jsx": () => import("./../../../src/components/About/index.jsx" /* webpackChunkName: "component---src-components-about-index-jsx" */),
  "component---src-components-career-index-jsx": () => import("./../../../src/components/Career/index.jsx" /* webpackChunkName: "component---src-components-career-index-jsx" */),
  "component---src-components-contact-index-jsx": () => import("./../../../src/components/Contact/index.jsx" /* webpackChunkName: "component---src-components-contact-index-jsx" */),
  "component---src-components-estimate-index-jsx": () => import("./../../../src/components/Estimate/index.jsx" /* webpackChunkName: "component---src-components-estimate-index-jsx" */),
  "component---src-components-home-index-jsx": () => import("./../../../src/components/Home/index.jsx" /* webpackChunkName: "component---src-components-home-index-jsx" */),
  "component---src-components-order-order-container-jsx": () => import("./../../../src/components/Order/OrderContainer.jsx" /* webpackChunkName: "component---src-components-order-order-container-jsx" */),
  "component---src-components-press-index-jsx": () => import("./../../../src/components/Press/index.jsx" /* webpackChunkName: "component---src-components-press-index-jsx" */),
  "component---src-components-privacy-policy-index-jsx": () => import("./../../../src/components/PrivacyPolicy/index.jsx" /* webpackChunkName: "component---src-components-privacy-policy-index-jsx" */),
  "component---src-components-services-additional-it-legal-assistance-index-jsx": () => import("./../../../src/components/Services/Additional/ITLegalAssistance/index.jsx" /* webpackChunkName: "component---src-components-services-additional-it-legal-assistance-index-jsx" */),
  "component---src-components-services-additional-translations-index-jsx": () => import("./../../../src/components/Services/Additional/Translations/index.jsx" /* webpackChunkName: "component---src-components-services-additional-translations-index-jsx" */),
  "component---src-components-services-cloud-email-email-container-jsx": () => import("./../../../src/components/Services/Cloud/Email/EmailContainer.jsx" /* webpackChunkName: "component---src-components-services-cloud-email-email-container-jsx" */),
  "component---src-components-services-cloud-hosting-hosting-container-jsx": () => import("./../../../src/components/Services/Cloud/Hosting/HostingContainer.jsx" /* webpackChunkName: "component---src-components-services-cloud-hosting-hosting-container-jsx" */),
  "component---src-components-services-cloud-vps-vps-container-jsx": () => import("./../../../src/components/Services/Cloud/Vps/VpsContainer.jsx" /* webpackChunkName: "component---src-components-services-cloud-vps-vps-container-jsx" */),
  "component---src-components-services-marketing-advertisement-index-jsx": () => import("./../../../src/components/Services/Marketing/Advertisement/index.jsx" /* webpackChunkName: "component---src-components-services-marketing-advertisement-index-jsx" */),
  "component---src-components-services-marketing-copywriting-index-jsx": () => import("./../../../src/components/Services/Marketing/Copywriting/index.jsx" /* webpackChunkName: "component---src-components-services-marketing-copywriting-index-jsx" */),
  "component---src-components-services-marketing-seo-optimisation-index-jsx": () => import("./../../../src/components/Services/Marketing/SeoOptimisation/index.jsx" /* webpackChunkName: "component---src-components-services-marketing-seo-optimisation-index-jsx" */),
  "component---src-components-services-marketing-website-positioning-index-jsx": () => import("./../../../src/components/Services/Marketing/WebsitePositioning/index.jsx" /* webpackChunkName: "component---src-components-services-marketing-website-positioning-index-jsx" */),
  "component---src-components-services-software-desktop-apps-index-jsx": () => import("./../../../src/components/Services/Software/DesktopApps/index.jsx" /* webpackChunkName: "component---src-components-services-software-desktop-apps-index-jsx" */),
  "component---src-components-services-software-mobile-apps-index-jsx": () => import("./../../../src/components/Services/Software/MobileApps/index.jsx" /* webpackChunkName: "component---src-components-services-software-mobile-apps-index-jsx" */),
  "component---src-components-services-software-support-index-jsx": () => import("./../../../src/components/Services/Software/Support/index.jsx" /* webpackChunkName: "component---src-components-services-software-support-index-jsx" */),
  "component---src-components-services-software-web-apps-index-jsx": () => import("./../../../src/components/Services/Software/WebApps/index.jsx" /* webpackChunkName: "component---src-components-services-software-web-apps-index-jsx" */),
  "component---src-components-services-software-webshops-index-jsx": () => import("./../../../src/components/Services/Software/Webshops/index.jsx" /* webpackChunkName: "component---src-components-services-software-webshops-index-jsx" */),
  "component---src-components-services-software-websites-index-jsx": () => import("./../../../src/components/Services/Software/Websites/index.jsx" /* webpackChunkName: "component---src-components-services-software-websites-index-jsx" */),
  "component---src-components-terms-index-jsx": () => import("./../../../src/components/Terms/index.jsx" /* webpackChunkName: "component---src-components-terms-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

