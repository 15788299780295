import { DEFAULT_HEADERS } from "../utils/api/headers";

const checkResponse = async response => {
  if (response.status !== 200 && response.status !== 201 && response.status !== 202) {
    throw response;
  }
};

export const query = async ({ url, method, currentData }) => {
  const response = await fetch(url, { method, headers: DEFAULT_HEADERS, body: JSON.stringify(currentData) });
  const data = await response.json();

  await checkResponse(response);
  return await data;
};
