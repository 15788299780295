import { GET_EMAIL_LIST } from "./actions";
import { setReducerState } from "../../../utils/common";

export function emailReducer(state = { data }, action) {
  const setState = data => setReducerState(state.data, action.errors, data);

  switch (action.type) {
    case GET_EMAIL_LIST:
      return setState(action.data);
    default:
      return state;
  }
}

const data = [
  {
    id: "EMAILPRO",
    name: "E-mail Pro",
    accounts: [
      {
        id: "1",
        value: "10 GB",
        price: {
          currency: "PLN",
          year: 48,
          quarter: 12
        }
      }
    ]
  },
  {
    id: "EMAILHX16",
    name: "Hosted Exchange 2016",
    accounts: [
      {
        id: "1",
        value: "50 GB",
        price: {
          currency: "PLN",
          year: 132,
          quarter: 33
        }
      },
      {
        id: "2",
        value: "300 GB",
        price: {
          currency: "PLN",
          year: 720,
          quarter: 180
        }
      }
    ]
  }
];
