import { GET_VPS_LIST } from "./actions";
import { setReducerState } from "../../../utils/common";

export function vpsReducer(state = { data }, action) {
  const setState = data => setReducerState(state.data, action.errors, data);

  switch (action.type) {
    case GET_VPS_LIST:
      return setState(action.data);
    default:
      return state;
  }
}

const data = [
  {
    id: "VPS001",
    name: "Value",
    variant: [
      {
        id: "V1",
        name: "Value",
        value: "",
        price: {
          currency: "PLN",
          year: 240,
          quarter: 60
        },
        modifier: []
      }
    ]
  },
  {
    id: "VPS002",
    name: "Essential",
    variant: [
      {
        id: "V1",
        name: "Essential",
        value: "",
        price: {
          currency: "PLN",
          year: 480,
          quarter: 120
        },
        modifier: []
      }
    ]
  },
  {
    id: "VPS003",
    name: "Comfort",
    variant: [
      {
        id: "V1",
        name: "Comfort",
        value: "",
        price: {
          currency: "PLN",
          year: 960,
          quarter: 240
        },
        modifier: []
      }
    ]
  },
  {
    id: "VPS004",
    name: "Elite",
    variant: [
      {
        id: "V1",
        name: "Elite",
        value: "",
        price: {
          currency: "PLN",
          year: 1440,
          quarter: 360
        },
        modifier: []
      }
    ]
  }
];
