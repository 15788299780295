import { combineReducers } from "redux";
import { hostingReducer } from "./packages/hosting/reducers";
import { vpsReducer } from "./packages/vps/reducers";
import { emailReducer } from "./packages/email/reducers";
import { monitoringReducer } from "./packages/monitoring/reducers";
import { orderReducer } from "./order/reducers";

const rootReducer = combineReducers({
  hosting: hostingReducer,
  vps: vpsReducer,
  email: emailReducer,
  monitoring: monitoringReducer,
  order: orderReducer
});

export default rootReducer;
