import PackagesService from "../../../services/PackagesService";

export const GET_MONITORING = "GET_MONITORING";

export async function getMonitoring() {
  try {
    const response = await PackagesService.getMonitoring();

    return { type: GET_MONITORING, data: response };
  } catch (err) {
    return { type: GET_MONITORING, errors: err };
  }
}
